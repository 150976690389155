
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import { TheMask } from 'vue-the-mask';

import Loading from '@/components/Loading/Loading.vue';
import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import AuthService from '@/services/Auth/AuthService';
import CookieUtil from '@/services/Util/cookieUtil';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { InstanceAmplitude } from '@/share/Util//Metrics/Metrics';
import { updateProfile } from '@/share/Util/Profile/Profile';
import {
  validateEmptyAndName,
  validate,
  validateEmptyAndEmail,
  validateEmptyAndPhone,
  validateEmptyAndPassword
} from '@/share/Util/Validators';

import { RegisterForm } from '../../interfaces/IForms';

const CHECKBOX_ACCEPTED = 1;
const CHECKBOX_DECLINED = 0;

const TYPE_REGISTER_CART = 1;

const MESSAGE = {
  MSG_SUCCESS_REGISTER: 'Cadastro realizado com sucesso.',
  MSG_CHECK_PHONE:
    'Seu número de celular já está associado a outra conta. Altere-o se desejar criar uma nova conta.',
  MSG_CHECK_EMAIL:
    'Seu e-mail já está associado a outra conta. Altere-o se desejar criar uma nova conta.',
  MSG_ERROR_DEFAULT: 'Verifique todos os dados, ocorreu um erro inesperado.'
};

@Component({
  components: {
    TheMask,
    Loading,
    FeedbackRequiredField
  }
})
export default class FormRegister extends Vue {
  private isLoading = false;

  private showCurrentPassword = false;

  private data: RegisterForm = {
    name: '',
    email: '',
    number: '',
    password: '',
    acceptEmail: false,
    acceptTerms: false
  };

  private authService = new AuthService();

  private nameField = { isError: true, message: '' };
  private emailField = { isError: true, message: '' };
  private numberField = { isError: true, message: '' };
  private passwordField = { isError: true, message: '' };

  get isValidateForm() {
    return (
      !this.emailField.isError
      && !this.nameField.isError
      && !this.passwordField.isError
      && !this.numberField.isError
      && this.data.acceptTerms
    );
  }

  @Watch('data.name')
  validateName() {
    if (this.data.name !== null) {
      this.nameField = { isError: false, message: '' };
    }
    const isValidateName = validate(validateEmptyAndName(this.data.name));
    this.nameField = {
      isError: !isValidateName,
      message: String(validateEmptyAndName(this.data.name))
    };
  }

  @Watch('data.email')
  validateEmail() {
    if (this.data.email !== null) {
      this.emailField = { isError: false, message: '' };
    }
    const isValidateEmail = validate(validateEmptyAndEmail(this.data.email));
    this.emailField = {
      isError: !isValidateEmail,
      message: String(validateEmptyAndEmail(this.data.email))
    };
  }

  @Watch('data.number')
  validatePhone() {
    if (this.data.number !== null) {
      this.numberField = { isError: false, message: '' };
    }
    const isValidateNumber = validate(validateEmptyAndPhone(this.data.number));
    this.numberField = {
      isError: !isValidateNumber,
      message: String(validateEmptyAndPhone(this.data.number))
    };
  }

  @Watch('data.password')
  validatePassword() {
    if (this.data.password !== null) {
      this.passwordField = { isError: false, message: '' };
    }

    const isValidatePassword = validate(validateEmptyAndPassword(this.data.password, false));

    this.passwordField = {
      isError: !isValidatePassword,
      message: String(validateEmptyAndPassword(this.data.password, false))
    };
  }

  async handleCreateAccount() {
    try {
      this.isLoading = true;

      if (!this.isValidateForm) return;

      const name = this.data?.name || '';

      const form = {
        login: this.data.email || '',
        password: this.data.password || '',
        name,
        phone: this.data.number || '',
        nickname: name.split(' ')[0],
        aceite_receberPromocoes: this.data.acceptEmail ? CHECKBOX_ACCEPTED : CHECKBOX_DECLINED,
        aceite_receberDicas: this.data.acceptEmail ? CHECKBOX_ACCEPTED : CHECKBOX_DECLINED,
        aceite_termosPolitica: this.data.acceptTerms ? CHECKBOX_ACCEPTED : CHECKBOX_DECLINED,
        consent_usoDadosPessoais: this.data.acceptTerms ? CHECKBOX_ACCEPTED : CHECKBOX_DECLINED,
        isRegister: TYPE_REGISTER_CART
      };

      const response = await this.authService.register(form);

      if (response?.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.$store.dispatch('Toast/setToast', {
          text: MESSAGE.MSG_SUCCESS_REGISTER,
          status: 'success'
        });

        const token = response?.data?.data?.token;

        this.setAmplitudeRegisterSuccess();

        await this.setSessionPlatform(token);
      }
    } catch (error) {
      const responseError = error?.response;

      if (responseError?.status === STATUS_RESPONSE_API.CLIENT_ERROR_FORBIDDEN) {
        if (responseError?.data.message === 'Phone in use') {
          this.$store.dispatch('Toast/setToast', {
            text: MESSAGE.MSG_CHECK_PHONE,
            status: 'error'
          });
        } else if (responseError?.data.message === 'Email in use') {
          this.$store.dispatch('Toast/setToast', {
            text: MESSAGE.MSG_CHECK_EMAIL,
            status: 'error'
          });
        }
      } else {
        this.$store.dispatch('Toast/setToast', {
          text: MESSAGE.MSG_ERROR_DEFAULT,
          status: 'error'
        });
      }

      this.setAmplitudeRegisterError(responseError?.data);
    } finally {
      this.isLoading = false;
    }
  }

  setTokenStorageAndCookie(token: string) {
    this.$store.commit('setToken', token);

    localStorage.setItem('token', token);

    CookieUtil.setCookie('auth_token', token);
  }

  async setSessionPlatform(token: string) {
    this.setTokenStorageAndCookie(token);

    this.$store.commit('initialState');
    this.$store.commit('setAppStart', true);

    await updateProfile();
  }

  handleRedirectLogin() {
    this.$emit('login');
  }

  setAmplitudeRegisterSuccess() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Register',
          message: 'success'
        }
      }
    });
  }

  setAmplitudeRegisterError(error: any) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Register',
          message: 'error',
          response: error
        }
      }
    });
  }
}
