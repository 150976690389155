

































import { Component, Vue, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import { DiscountForm } from '../../interfaces/IForms';

const NAME_BEFORE_APPLY_DISCOUNT = 'Aplicar';
const NAME_REMOVE_APPLY_DISCOUNT = 'Remover';

@Component({
  components: {
    Loading
  }
})
export default class FormDiscount extends Vue {
  @Prop({ required: true }) data!: DiscountForm;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) isApply!: boolean;

  get nameButton() {
    return this.isApply ? NAME_REMOVE_APPLY_DISCOUNT : NAME_BEFORE_APPLY_DISCOUNT;
  }

  handleDiscount() {
    this.$emit('discount');
  }
}
