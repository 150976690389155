










































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import PurchaseSummary from '../../components/PurchaseSummary/PurchaseSummary.vue';
import PaymentForm from '../../components/PaymentForm/PaymentForm.vue';
import ModalDescriptionsPlan from '../../components/ModalDescriptionsPlan/ModalDescriptionsPlan.vue';
import ProgressPayment from '../../components/ProgressPayment/index.vue';
import ContainerAccess from '../../components/ContainerAccess/ContainerAccess.vue';

import PaymentLoading from './PaymentLoading.vue';

import PlansService from '@/services/Plans/PlansService';

import { Plan } from '../../interfaces/IShopping';

@Component({
  components: {
    PageHeader,
    Footer,
    FeedbackUser,
    PurchaseSummary,
    PaymentForm,
    PaymentLoading,
    ModalDescriptionsPlan,
    ProgressPayment,
    ContainerAccess
  }
})
export default class Payment extends Vue {
  private isLoadingPlan = false;

  private plan: Plan | null = null;
  private listPlans: Plan[] | null = null;

  private PlansService = new PlansService();

  created() {
    this.handleFeature();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get planId() {
    return Number(this.$route.params.productId);
  }

  get userId() {
    return this.$store.getters.profile?.idstudent;
  }

  async getPlan() {
    try {
      this.isLoadingPlan = true;

      const response = await this.PlansService.getPlan({
        planId: Number(this.planId),
        withRelations: 1
      });

      if (response) {
        this.plan = response;
      }

      this.setListPlans();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o plano.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingPlan = false;
    }
  }

  async handleFeature() {
    const { feature } = this.$route.params;

    if (feature.includes('plano')) {
      await this.getPlan();
    }
  }

  setListPlans() {
    let plans: Plan[] | null = null;

    if (this.plan) {
      plans = [this.plan];

      if (this.plan?.plansRelatedData) {
        plans = [...plans, ...this.plan.plansRelatedData];
      }
    }

    this.listPlans = this.setOrderPlanInterval(plans);
  }

  setOrderPlanInterval(plans: Plan[] | null) {
    if (!plans) return plans;

    const order = [1, 3, 6, 12];

    return plans.sort((a, b) => {
      const indexA = order.indexOf(a.planIntervalCount);
      const indexB = order.indexOf(b.planIntervalCount);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
  }

  handleActivePlan(planActive: Plan) {
    if (!planActive) return;

    const { planId } = planActive;
    const { feature } = this.$route.params;

    this.$router.push({
      name: 'Payment',
      params: {
        feature,
        productId: String(planId)
      }
    });

    this.plan = { ...planActive };
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Loja', to: '/loja' },
      { title: 'Pagamento', to: null }
    ]);
  }
}
