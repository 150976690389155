














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProgressPayment extends Vue {
  @Prop({ default: '15' }) percent!: number;
}
